import React, {Component} from 'react'
import { MenuItems } from './MenuItems';
import './Navbar.css';

class Navbar extends Component {

    state = { clicked: false}
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }

    removeBackdrop = () => {
        this.setState({ clicked: false })
    }
        
    render() {
        return (
            <nav className="NavbarItems shadow-sm">                        
                <h1 className="navbar-logo"><a href="/"><i className="fab fa-pushed d-inline"></i><span className="margin-logo"></span></a></h1>
                <div className="menu-icon" onClick={this.handleClick}>
                    <i className={this.state.clicked ? 'fas fa-times' :  'fas fa-bars'}></i>
                </div>
                <ul className={this.state.clicked ? 'nav-menu active' : 'nav-menu'} onClick={this.removeBackdrop}>
                    {MenuItems.map((item, index)=> {
                        return (
                            <li key={index}>
                                <a className={item.cName} href={item.url}>{item.title}</a>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        )
    }
}

export default Navbar;
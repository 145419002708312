export const MenuItems = [
    {
        title: '<Home/>',
        url: '/#home',
        cName: 'nav-links'
    },
    {
        title: '<Services/>',
        url: '/#services',
        cName: 'nav-links'
    },
    {
        title: '<Portfolio/>',
        url: '/#portfolio',
        cName: 'nav-links'
    },
    {
        title: '<Contact/>',
        url: '/#contact',
        cName: 'nav-links'
    },
]
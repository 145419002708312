import React from 'react'
import "./Portfolio.css"
// import SmoothList from 'react-smooth-list';

export default function Portfolio() {
    return (
        <div className="row" id="portfolio">
            <div className="col-md-12 d-flex flex-wrap align-items-center full-screen">
                <div className="row mt-5 pt-5">
                    <div className="title mb-4">
                        <h1>Portfolio</h1>
                    </div>
                
                    <div className="col-lg-4 col-md-4 col-sm-12 mb-5"> 
                        <div className="card">
                            <div className="cad-body p-3">
                                <div className="row">
                                    <div className="col-6">
                                        <i className="far fa-folder folder-icon"></i>
                                    </div>
                                    <div className="col-6 text-end">
                                        <a href="https://cbrewards.com" target="_blank" rel = "noopener noreferrer"><i className="fas fa-external-link-alt link-icon"></i></a>
                                    </div>
                                </div>
                                <div className="project-title">
                                    <h4>CBRewards</h4>
                                </div>
                                <div className="project-details">
                                    Building a custom platform with PHP Laravel from an API for monitoring and offering rank rewards based on the hours broadcasted, users and performers analithic graphs
                                </div>
                                <div className="project-tech">
                                    PHP, Laravel, JS, AmCharts, Ajax, MySQL
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 mb-5"> 
                        <div className="card">
                            <div className="cad-body p-3">
                                <div className="row">
                                    <div className="col-6">
                                        <i className="far fa-folder folder-icon"></i>
                                    </div>
                                    <div className="col-6 text-end">
                                        <a href="https://rapidapi.com/huzumsergiu/api/covid-19-pandemic-live-news" target="_blank" rel = "noopener noreferrer"><i className="fas fa-external-link-alt link-icon"></i></a>
                                    </div>
                                </div>
                                <div className="project-title">
                                    <h4>Covid News API</h4>
                                </div>
                                <div className="project-details">
                                    Building a API for Covid-19 Pandemic News arround the world from different publications from different countries (Guardian, The Times, Reuters, Telegraph ABC-News, BBC-News) 
                                </div>
                                <div className="project-tech">
                                    NodeJS, Axios, Cheerio
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 mb-5"> 
                        <div className="card">
                            <div className="cad-body p-3">
                                <div className="row">
                                    <div className="col-6">
                                        <i className="far fa-folder folder-icon"></i>
                                    </div>
                                    <div className="col-6 text-end">
                                        <a href="https://modelpeek.com" target="_blank" rel = "noopener noreferrer"><i className="fas fa-external-link-alt link-icon"></i></a>
                                    </div>
                                </div>
                                <div className="project-title">
                                    <h4>ModelPeek</h4>
                                </div>
                                <div className="project-details">
                                    Building a custom platform with PHP Laravel from many API, combining them in orther to monitor perfomers data, best broadcasting peaks, perfomers regions with graphs 
                                </div>
                                <div className="project-tech">
                                    PHP, Laravel, JS, AmCharts, MySQL
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 mb-5"> 
                        <div className="card">
                            <div className="cad-body p-3">
                                <div className="row">
                                    <div className="col-6">
                                        <i className="far fa-folder folder-icon"></i>
                                    </div>
                                    <div className="col-6 text-end">
                                        <a href="https://snapdesign.ro" target="_blank" rel = "noopener noreferrer"><i className="fas fa-external-link-alt link-icon"></i></a>
                                    </div>
                                </div>
                                <div className="project-title">
                                    <h4>SnapDesign</h4>
                                </div>
                                <div className="project-details">
                                    Building a custom mobile responsive website in PHP Laravel for promoting webdesign and coding services, implemented live chat with staff
                                </div>
                                <div className="project-tech">
                                    PHP, HTML, CSS, JS
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 mb-5"> 
                        <div className="card">
                            <div className="cad-body p-3">
                                <div className="row">
                                    <div className="col-6">
                                        <i className="far fa-folder folder-icon"></i>
                                    </div>
                                    <div className="col-6 text-end">
                                        <a href="#!" target="_blank" rel = "noopener noreferrer"><i className="fas fa-external-link-alt link-icon"></i></a>
                                    </div>
                                </div>
                                <div className="project-title">
                                    <h4>VIP Escort</h4>
                                </div>
                                <div className="project-details">
                                    Building a custom platform in PHP Laravel for escorts ads, messaging system, voting and rating, promoting system, backend with multiple features
                                </div>
                                <div className="project-tech">
                                    PHP, Laravel, MySQL, Axios, Sockets
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-12 mb-5"> 
                        <div className="card">
                            <div className="cad-body p-3">
                                <div className="row">
                                    <div className="col-6">
                                        <i className="far fa-folder folder-icon"></i>
                                    </div>
                                    <div className="col-6 text-end">
                                        <a href="https://frigotek.ro/" target="_blank" rel = "noopener noreferrer"><i className="fas fa-external-link-alt link-icon"></i></a>
                                    </div>
                                </div>
                                <div className="project-title">
                                    <h4>Frigotek</h4>
                                </div>
                                <div className="project-details">
                                    Building a company website where users can book a apointment and diagnostic estimation based distance, implemented live chat with staff 
                                </div>
                                <div className="project-tech">
                                    PHP, HTML, CSS, JS, MySQL
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 vs-code-image-holder mt-5">
                        <h1>VSCode Theme</h1>
                        <p>A fresh, clean and minimalistic VSCode Theme for you to enjoy</p>
                        <a href="https://marketplace.visualstudio.com/items?itemName=ProtonCoding.protoncoding-theme" target="_blank" rel = "noopener noreferrer"><img src="img/vs_code_theme.png" alt="vs_code_theme.png" width="100%"></img></a>
                    </div>

                </div>
            </div>
        </div>
    )
}

import React from 'react'
import './LeftSidebar.css';


export default function LeftSidebar() {
    return (
        <div className="sidebar-left">
            <div className="sidebar-properties">
                <div className="social-icons-holder">
                    
                    <div className="social-btn">
                        <a href='https://github.com/protoncoding' target="_blank" rel = "noopener noreferrer">
                            <i className="fa fa-github"></i>
                        </a>
                    </div>

                    <div className="social-btn">
                        <a href='https://twitter.com/CodingProton' target="_blank" rel = "noopener noreferrer">
                            <i className='fa fa-twitter'></i>
                        </a>
                    </div>

                    <div className="social-btn">
                        <a href='skype:protoncoding?chat'>
                            <i className='fa fa-skype'></i>
                        </a>
                    </div>

                    <div className="social-btn">
                        <a href='https://www.linkedin.com/in/protoncoding-management-903a48227/' target="_blank" rel = "noopener noreferrer">
                            <i className='fa fa-linkedin'></i>
                        </a>
                    </div>
                    <div className="social-icons-bar"></div>
                </div>
            </div>
        </div>
    )
}

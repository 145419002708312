// import './App.css';
import Home from './Components/Pages/Home';
import LeftSidebar from './Components/LeftSidebar/LeftSidebar';
import RightSidebar from './Components/RightSidebar/RightSidebar';
import Navbar from './Components/Header/Navbar';
import Services from './Components/Pages/Services';
import Portfolio from './Components/Pages/Portfolio';
import Contact from './Components/Pages/Contact';

function App() {
  return (
    <div className="App" id="home">
      <div className="row justify-content-center">
        <div className="col-lg-1 p-0" style={{ position: "fixed", left: "0"}}><LeftSidebar/></div>
        <div className="col-lg-9 col-md-9 col-sm-12 col-12 p-5" style={{ maxWidth: "1196px"}}>
          <Navbar/>
          <Home/>
          <Services/>
          <Portfolio/>
          <Contact/>
          
        </div>
        <div className="col-lg-1 p-0" style={{ position: "fixed", right: "0"}}><RightSidebar/></div>
      </div>
      
    </div>
  );
}

export default App;

import React from 'react'
import './RightSidebar.css';


export default function RightSidebar() {
    return (
        <div className="sidebar-right">
            <div className="sidebar-properties">
                <div className="social-icons-holder">
                    <div className="social-btn">
                        <a href='mailto:protoncoding@gmail.com' className="email-holder" rel = "noopener noreferrer">
                            protoncoding@gmail.com
                        </a>
                    </div>
                    <div className="social-icons-bar"></div>
                </div>
            </div>
        </div>
    )
}
